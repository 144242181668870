import React, { useEffect, useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'

//import '../styles/main.css'
import { Power1 } from 'gsap/gsap-core'

const Arrows = ({ page, setPage }) => {
    const root = useRef()
    const q = gsap.utils.selector(root)

    useLayoutEffect(() => {
        gsap.timeline({ repeat: -1, yoyo: true }).to(q('.right-arrow'), {
            x: 10,
            duration: 0.5,
            ease: Power1.easeInOut,
        })

        gsap.timeline({ repeat: -1, yoyo: true }).to(q('.left-arrow'), {
            x: -10,
            duration: 0.5,
            ease: Power1.easeInOut,
        })
    }, [])

    useEffect(() => {}, [page])

    return (
        <div className="arrow-container" ref={root}>
            <svg
                width="43"
                height="71"
                viewBox="0 0 43 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`left-arrow ${
                    page === 0
                        ? 'transparent button-disabled'
                        : 'button-enabled'
                }`}
                onClick={() => {
                    setPage(page - 1)
                }}
            >
                <line
                    x1="35.5386"
                    y1="7.60989"
                    x2="7.68588"
                    y2="35.4626"
                    stroke="#EFB14D"
                    stroke-width="10"
                    stroke-linecap="round"
                />
                <line
                    x1="7.53201"
                    y1="35.6151"
                    x2="35.3847"
                    y2="63.4677"
                    stroke="#EFB14D"
                    stroke-width="10"
                    stroke-linecap="round"
                />
            </svg>

            <svg
                width="43"
                height="71"
                viewBox="0 0 43 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`right-arrow ${
                    page === 0 || page === 3
                        ? 'transparent button-disabled'
                        : 'button-enabled'
                }`}
                onClick={() => {
                    setPage(page + 1)
                }}
            >
                <line
                    x1="7.46094"
                    y1="63.4678"
                    x2="35.3136"
                    y2="35.6151"
                    stroke="#EFB14D"
                    stroke-width="10"
                    stroke-linecap="round"
                />
                <line
                    x1="35.4675"
                    y1="35.4626"
                    x2="7.61481"
                    y2="7.60989"
                    stroke="#EFB14D"
                    stroke-width="10"
                    stroke-linecap="round"
                />
            </svg>
        </div>
    )
}

export default Arrows
