import React from 'react'
import { useState } from 'react'

//import '../styles/main.css'

const Navbar = ({ page, clickCallback }) => {
    return (
        <div className="navbar">
            <a
                className={`nav-item nav-home ${page === 0 && 'nav-highlight'}`}
                onClick={() => clickCallback(0)}
            >
                Home
            </a>
            <a
                className={`nav-item nav-about ${
                    page === 1 && 'nav-highlight'
                }`}
                onClick={() => clickCallback(1)}
            >
                About
            </a>
            <a
                className={`nav-item nav-work ${page === 2 && 'nav-highlight'}`}
                onClick={() => clickCallback(2)}
            >
                Work
            </a>
            <a
                className={`nav-item nav-contact ${
                    page === 3 && 'nav-highlight'
                }`}
                onClick={() => clickCallback(3)}
            >
                Contact
            </a>
        </div>
    )
}

export default Navbar
