import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'

//import '../styles/main.css'
import { Power2, Power1, Linear, Power0 } from 'gsap/gsap-core'

const Hero = ({ page, cta }) => {
    const root = useRef()
    const q = gsap.utils.selector(root)

    useEffect(() => {
        let active = page === 0

        gsap.killTweensOf([q('.title-name'), q('.title-job'), q('.cta')])

        if (active) {
            gsap.to([q('.title-name'), q('.title-job'), q('.cta')], {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: Power1.easeInOut,
            }).delay(1)
        } else {
            gsap.to([q('.title-name'), q('.title-job'), q('.cta')], {
                opacity: 0,
                y: -100,
                duration: 1,
                ease: Power1.easeInOut,
            })
        }
    }, [page])

    return (
        <div className="hero-container" ref={root}>
            <h1 className="title-name">Calcedo</h1>
            <h2 className="title-job">Software Engineer</h2>
            <a
                className={`cta ${page !== 0 && 'button-disabled'}`}
                onClick={() => cta(1)}
            >
                Explore my city!
            </a>
        </div>
    )
}

export default Hero
