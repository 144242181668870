import boxTest from './components/BoxTest.jsx'
import CityAnimation from './components/CityAnimation'
import CityFilm from './components/CityFilm'
import Hero from './components/Hero'
import Navbar from './components/Navbar'
import BoxTest from './components/BoxTest.jsx'
import { useState } from 'react'

import './styles/index.css'
import About from './components/About.jsx'
import Work from './components/Work.jsx'
import Contact from './components/Contact.jsx'
import Arrows from './components/Arrows.jsx'

function App() {
    const [page, setPage] = useState(0)

    return (
        <div className="App">
            <CityFilm page={page} setPage={setPage}></CityFilm>
            {/*
            <Navbar page={page} clickCallback={setPage}></Navbar>
            <Hero page={page} cta={setPage}></Hero>
            <About page={page}></About>
            <Work page={page}></Work>
            <Contact page={page}></Contact>
            */}
        </div>
    )
}

export default App
