import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Power1 } from 'gsap/gsap-core'
import gsap from 'gsap'

import { useForm, ValidationError } from '@formspree/react'
import { BiLoaderCircle } from 'react-icons/bi'
import { BiMailSend } from 'react-icons/bi'
import { RiMailCloseLine } from 'react-icons/ri'

const ContactForm = ({ page }) => {
    const [state, handleSubmit] = useForm('myylvoez')
    const [inputs, setInputs] = useState({
        name: '',
        email: '',
        message: '',
    })
    const handleOnChange = (event) => {
        event.persist()
        setInputs((prev) => ({
            ...prev,
            [event.target.id]: event.target.value,
        }))
    }
    const [emailFeedback, setEmailFeedback] = useState(<p></p>)
    const [valid, setValid] = useState(false)

    const root = useRef()
    const q = gsap.utils.selector(root)

    useEffect(() => {
        let active = page === 3

        gsap.killTweensOf([q('.form-container')])

        if (active) {
            gsap.to([q('.form-container')], {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: Power1.easeInOut,
            }).delay(1)
        } else {
            gsap.to([q('.form-container')], {
                opacity: 0,
                y: -100,
                duration: 1,
                ease: Power1.easeInOut,
            })
        }
    }, [page])

    const handleOnSubmit = (event) => {
        event.preventDefault()

        gsap.killTweensOf(q('.email-feedback'))
        gsap.timeline()
            .to(q('.email-feedback'), {
                y: 200,
                duration: 0,
                onComplete: () => {
                    setValid(false)
                },
            })
            .to(q('.email-feedback'), {
                opacity: 1,
                y: 0,
                duration: 0.5,
                ease: Power1.easeInOut,
            })
            .to({}, { duration: 2 })
            .to(q('.email-feedback'), {
                opacity: 0,
                y: -200,
                duration: 0.5,
                ease: Power1.easeInOut,
            })
            .to(q('.email-feedback'), {
                y: 0,
                duration: 0,
                onComplete: () => {
                    setValid(false)
                },
            })

        if (inputs.name === '') {
            return setEmailFeedback(
                <p>
                    Please, provide <br /> a <span>name</span>
                </p>
            )
        } else if (inputs.email === '') {
            return setEmailFeedback(
                <p>
                    Please, provide <br /> an <span>email</span>
                </p>
            )
        } else if (inputs.message === '') {
            return setEmailFeedback(
                <p>
                    Please, provide <br /> a <span>message</span>
                </p>
            )
        }

        setEmailFeedback(
            <p>
                <span>Sending...</span>
                <br />
                <BiLoaderCircle className="icon" />
            </p>
        )
        axios({
            url: 'https://formspree.io/f/myylvoez',
            method: 'POST',
            data: inputs,
        })
            .then((r) => {
                setValid(true)
                setEmailFeedback(
                    <p>
                        <span>Mail sent!</span>
                        <br />
                        <BiMailSend className="icon" />
                    </p>
                )
            })
            .catch((r) => {
                setEmailFeedback(
                    <p>
                        <span>Could not send!</span>
                        <br />
                        <RiMailCloseLine className="icon" />
                    </p>
                )
            })

        setInputs({
            name: '',
            email: '',
            message: '',
        })
    }

    return (
        <div ref={root}>
            <form
                onSubmit={handleOnSubmit}
                autoComplete="off"
                spellCheck="false"
                className={page !== 3 && 'transparent button-disabled'}
            >
                <div className="form-container">
                    <label for="name" className="yellow-glow">
                        Name
                    </label>
                    <input
                        name="Name"
                        id="name"
                        type="text"
                        value={inputs.name}
                        onChange={handleOnChange}
                    ></input>
                    <label for="email" className="yellow-glow">
                        Email
                    </label>
                    <input
                        name="Email"
                        id="email"
                        type="email"
                        value={inputs.email}
                        onChange={handleOnChange}
                    ></input>
                    <label for="message" className="yellow-glow">
                        Message
                    </label>
                    <textarea
                        name="Message"
                        id="message"
                        type="text"
                        rows="4"
                        value={inputs.message}
                        onChange={handleOnChange}
                    ></textarea>
                    <button type="submit" className="form-button">
                        Send!
                    </button>
                </div>
                <p
                    className={`email-feedback ${
                        valid === true ? 'valid-feed' : 'invalid-feed'
                    }`}
                >
                    {emailFeedback}
                </p>
            </form>
        </div>
    )
}

export default ContactForm
