import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { Power2, Power1, Linear, Power0 } from 'gsap/gsap-core'

//import '../styles/main.css'

const About = ({ page }) => {
    const root = useRef()
    const q = gsap.utils.selector(root)

    useEffect(() => {
        let active = page === 1

        gsap.killTweensOf([q('.zone-name'), q('.zone-desc')])

        if (active) {
            gsap.to([q('.zone-name'), q('.zone-desc')], {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: Power1.easeInOut,
            }).delay(1)
        } else {
            gsap.to([q('.zone-name'), q('.zone-desc')], {
                opacity: 0,
                y: -100,
                duration: 1,
                ease: Power1.easeInOut,
            })
        }
    }, [page])

    return (
        <div className="about-container" ref={root}>
            <h1 className={`zone-name ${page !== 1 && 'transparent'}`}>
                <span>About me</span> Park
            </h1>
            <p className={`zone-desc ${page !== 1 && 'transparent'}`}>
                Hi! I'm Germán Calcedo, a <span>detail-oriented</span> and{' '}
                <span>passionate</span> software engineer from northern Spain. Currently
                living in the Dutch city of <span>Groningen!</span>
            </p>
        </div>
    )
}

export default About
