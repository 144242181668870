import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { Power2, Power1, Linear, Power0 } from 'gsap/gsap-core'

//import '../styles/main.css'
const Contact = ({ page }) => {
    const root = useRef()
    const q = gsap.utils.selector(root)

    useEffect(() => {
        let active = page === 3

        gsap.killTweensOf(q('.zone-name'))

        if (active) {
            gsap.to([q('.zone-name')], {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: Power1.easeInOut,
            }).delay(1)
        } else {
            gsap.to([q('.zone-name')], {
                opacity: 0,
                y: -100,
                duration: 1,
                ease: Power1.easeInOut,
            })
        }
    }, [page])

    return (
        <div className="about-container" ref={root}>
            <h1 className={`zone-name ${page !== 3 && 'transparent'}`}>
                <span>Contact</span> Boulevard
            </h1>
        </div>
    )
}

export default Contact
